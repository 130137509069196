.radio-group-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.radio-group-option-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 6px;
    cursor: pointer;
    border-radius: 8px;
}

.radio-group-option-container:hover {
    background-color: #f8f8f8;
}

.radio-group-option-container-active:hover {
    background-color: transparent;
}

.radio-group-radio {
    flex: 0;
}

.radio-group-option-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.radio-group-input {
    padding: 4px 0px 8px 0px;
}