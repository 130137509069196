.stayql-card-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.stayql-card-label {
    flex: 1;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
}

.stayql-card-value {
    flex: 2;
}