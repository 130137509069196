.triggers-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.triggers-list-category-container {
    display: flex;
    flex-direction: column;
}

.triggers-list-category-header {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.triggers-list-trigger-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 8px;
    border-radius: 8px;
}

.triggers-list-trigger-container:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}

.triggers-list-trigger-checkbox {
    flex: 0;
}

.triggers-list-trigger-label {
    flex: 1;
}