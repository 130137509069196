.tile-row-editable {
    /* border: 1px solid red; */
    position: relative;
    padding-top: 48px;
    min-height: 100px;
}

.tile-row-toolbelt {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.tile-row-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin: -8px;
}