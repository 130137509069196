.nodes-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.nodes-list-category-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nodes-list-category-header {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.nodes-list-node-container {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    text-align: center;
}

.nodes-list-node-container:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}