.tile-col-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.tile-col-editable {
    position: relative;
    padding-top: 48px;
}

.tile-col-span-1 {
    flex-basis: 25%;
}

.tile-col-span-2 {
    flex-basis: 50%;
}

.tile-col-span-3 {
    flex-basis: 75%;
}

.tile-col-span-4 {
    flex-basis: 100%;
}

.tile-col-toolbelt {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.tile-col-content {
    margin: 8px;
}

.tile-col-component-empty {
    height: 100px;
    background-color: rgb(230, 230, 230);
    border: 1px solid rgb(200, 200, 200);
    border-radius: 8px;
}